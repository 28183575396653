import FormData from 'form-data';
import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";

class SignIn extends Component {
  constructor(props) {
    super(props);

    /* Steps: 
            0 - log in (default)
            1 - forgot password
            2 - link on way
            3 - reset password
        */
    this.state = {
      step: 0,
      error: null,
      loggedIn: false,
      email: "",
      password: "",
      resetToken: "",
      resetEmail: null,
    };
  }

  componentDidMount = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (
      urlParams.get("s") === "reset_password_email" &&
      urlParams.get("token") !== null
    ) {
      this.setState({ step: 3, error: "", resetToken: urlParams.get("token") });
    }
  };
  handleForgotPassword = (event) => {
    this.setState({ step: 1, error: "" });
  };

  handleSendResetLink = (event, reSend = false) => {
    let email;
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();

    if (reSend) {
      email = this.state.resetEmail;
    } else {
      email = document.getElementById("forgotEmail").value;
      this.setState({ resetEmail: email });
    }

    if (email === "") {
      this.setState({
        error: "Email address is required",
      });
      return false;
    }

    bodyFormData.set("hID", "sendResetLink");
    bodyFormData.set("email", email);

    axios({
      method: "post",
      url: apiUrl + "login.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (
          response.data.status === "false" ||
          response.data.status === "fail"
        ) {
          that.setState({ error: response.data.code });
        } else {
          that.setState({ step: 2, error: "" });

          if (reSend) {
            // notify user we re-sent
            that.setState({ error: "We have resent a link to your email" });
          }
        }
      })
      .catch(function (response) {
        console.log("Error sending reset link!");
      });
  };

  validPassword = (pw) => {
    // 6-12 characters, containing at least one numeric digit and a special character
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/;
    if(!pw.match(passwordRegex)) {
      return false;
    }
    return true;
  }

  handleResetPassword = (event) => {
    let email = document.getElementById("resetEmail").value;
    let pw = document.getElementById("resetNewPassword").value;
    let pwCopy = document.getElementById("resetNewPasswordCopy").value;
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();

    if (email === "" || pw === "" || pwCopy === "") {
      this.setState({
        error: "Email address and passwords are required",
      });
      return false;
    } else if (pw !== pwCopy) {
      this.setState({
        error: "Passwords do not match",
      });
      return false;
    } else if(!this.validPassword(pw)) {
      this.setState({
        error: "Password must be 6-12 characters, containing at least one numeric digit and a special character",
      });
      return false;
    }

    bodyFormData.set("hID", "resetPassword");
    bodyFormData.set("email", email);
    bodyFormData.set("pw", pw);
    bodyFormData.set("pwCopy", pwCopy);
    bodyFormData.set("token", this.state.resetToken);

    let loginObj = {
      email: email,
      pw: pw,
    };

    axios({
      method: "post",
      url: apiUrl + "login.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (
          response.data.status === "false" ||
          response.data.status === "fail"
        ) {
          that.setState({ error: response.data.code });
        } else {
          that.handleLogin(null, loginObj);
        }
      })
      .catch(function (response) {
        console.log("Error sending reset link!");
      });
  };

  handleLogin = (event, loginObj) => {
    let user, pw;

    if (!loginObj) {
      user = document.getElementById("loginEmail").value.trim();
      pw = document.getElementById("loginPassword").value.trim();
    } else {
      user = loginObj.email;
      pw = loginObj.pw;

      // delete URL params
      window.history.pushState({}, document.title, "/");
      // const queryString = window.location.search;
      // const urlParams = new URLSearchParams(queryString);
      // urlParams.delete('s');
      // urlParams.delete('token');
    }

    if (user === "" || pw === "") {
      this.setState({
        error: "Email address and password are required",
      });
      return false;
    }

    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();

    bodyFormData.set("hID", "login");
    bodyFormData.set("email", user);
    bodyFormData.set("pwd", pw);

    axios({
      method: "post",
      url: apiUrl + "login.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (
          response.data.status === "false" ||
          response.data.status === "fail"
        ) {
          that.setState({ error: response.data.code });
        } else {
          that.setState({
            loggedIn: true,
            error: "",
          });
          that.props.onLogin(response.data);
        }
      })
      .catch(function (response) {
        console.log("Error logging in");
      });
  };

  onKeyPressed = (event) => {
    if (event.key === "Enter") {
      this.handleLogin(null);
    }
  }

  render() {
    let errorDiv;
    if (this.state.error) {
      errorDiv = <div className="error">{this.state.error}</div>;
    } else {
      errorDiv = <span></span>;
    }

    return (
      <div className="app__login-panel">
        <div className="app__left-panel">
        </div>
        <div className="app__right-panel">
        <div id="login" className={`${this.state.step === 0 ? "" : "hidden"}`}>
          <h1 className="app__login-panel__title">Welcome</h1>
          <p>Please log in to your account.</p>
          <label>
            <p>Email Address</p>
            <input
              id="loginEmail"
              className="admin__input-text"
              type="text"
              name="username"
              onKeyPress={this.onKeyPressed}
            ></input>
          </label>
          <label>
            <p>Password</p>
            <input
              id="loginPassword"
              className="admin__input-text"
              name="password"
              type="password"
              onKeyPress={this.onKeyPressed}
            ></input>
          </label>
          <div className="admin__error-div admin__error-div--welcome">
          {this.state.error &&
          <div>
            <i className="fa fa-exclamation-triangle"></i> {this.state.error}
            </div>
          }
          </div>
          <button
            onClick={(event) => this.handleLogin(event, false)}
            className="admin__button"
          >
            Log in <i className="fa fa-play"></i>
          </button>
          <div className="clearfix"></div>
          <a href="#" onClick={this.handleForgotPassword}>
            Forgot Password?
          </a>
          <div className="admin__logo"></div>
        </div>

        <div
          id="forgotPassword"
          className={`${this.state.step === 1 ? "" : "hidden"}`}
        >
          <div className="admin__error-div admin__error-div--resetpassword">
            {this.state.error}
          </div>
          <h1 className="app__login-panel__title">Forgot Password</h1>
          <p>
            Please enter your email address and a link will be sent to you to
            reset your password
          </p>

          <label>
            <p>Email Address</p>
            <input
              id="forgotEmail"
              className="admin__input-text"
              type="text"
              name="username"
            ></input>
          </label>
          <button onClick={this.handleSendResetLink} className="admin__button send__reset">
            Send Link
          </button>
          <div className="admin__logo"></div>
        </div>

        <div
          id="linkOnWay"
          className={`${this.state.step === 2 ? "" : "hidden"}`}
        >
          <h1 className="app__login-panel__title">Your Link is on Its Way!</h1>
          <p>
            Please check your inbox for a link to reset your password.
            <br />
            <br />
            Didn't get a link?
          </p>
          <button
            onClick={(event) => this.handleSendResetLink(event, true)}
            className="admin__button"
          >
            Resend
          </button>
          {errorDiv}
          <div className="admin__logo"></div>
        </div>

        <div
          id="resetPassword"
          className={`${this.state.step === 3 ? "" : "hidden"}`}
        >
          <div className="admin__error-div admin__error-div--resetpassword">
            {this.state.error}
          </div>
          <h1 className="app__login-panel__title">Reset Password</h1>
          <label>
            <p>Email Address</p>
            <input
              id="resetEmail"
              className="admin__input-text"
              type="text"
              name="resetEmail"
            ></input>
          </label>
          <label>
            <p>New Password</p>
            <input
              id="resetNewPassword"
              className="admin__input-text"
              name="resetNewPassword"
              type="password"
            ></input>
          </label>
          <label>
            <p>Confirm Password</p>
            <input
              id="resetNewPasswordCopy"
              className="admin__input-text"
              name="resetNewPasswordCopy"
              type="password"
            ></input>
          </label>
          <button onClick={this.handleResetPassword} className="admin__button">
            ok
          </button>
          <div className="admin__logo"></div>
        </div>
      </div>
      </div>
    );
  }
}

export default SignIn;
