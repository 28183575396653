import React, { Component } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import DatePicker from "react-datepicker";
import Loader from "react-loader-spinner";
import config from "react-global-configuration";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "-sort-asc"
                        : "-sort-desc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page {pageIndex + 1} of {pageOptions.length}{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

class ActivityTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      activity: [],
      origActivity: [],
      startDate: null,
      endDate: null,
      segments: [],
      retailSegment: 0,
      brandId: 0,
      isLoading: false,
    };

    this.getData();
  }

  componentDidMount = () => {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);

    this.setState({endDate: today, startDate: lastWeek}, () => {
      this.getData();
    });
    
  };

  getData = () => {
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    let that = this;

    if (!document.getElementById("activityStartDate")) {
      return;
    }

    let sDate = new Date(
      document.getElementById("activityStartDate").value.replace(/-/g, "/")
    );
    let eDate = new Date(
      document.getElementById("activityEndDate").value.replace(/-/g, "/")
    );

    let sDay = sDate.getDate();
    if (sDay.toString().length === 1) {
      sDay = "0" + sDay;
    }
    let eDay = eDate.getDate();
    if (eDay.toString().length === 1) {
      eDay = "0" + eDay;
    }
    let sMonth = sDate.getMonth() + 1;
    if (sMonth.toString().length === 1) {
      sMonth = "0" + sMonth;
    }
    let eMonth = eDate.getMonth() + 1;
    if (eMonth.toString().length === 1) {
      eMonth = "0" + eMonth;
    }

    let startDate =
      sDate.getFullYear() + "-" + sMonth + "-" + sDay + "T00:00:00";
    let endDate = eDate.getFullYear() + "-" + eMonth + "-" + eDay + "T23:59:59";

    // let brand = document.getElementById("activityBrand").value;

    bodyFormData.set("hID", "getActivity");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("fromEmail", this.props.user.email);
    bodyFormData.set("startDate", startDate);
    bodyFormData.set("endDate", endDate);
    bodyFormData.set("retailSegment", this.state.retailSegment);
    bodyFormData.set("brandId", this.state.brandId);
    bodyFormData.set("apiKey", this.props.user.api_key);

    this.setState({ isLoading: true });

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (
          response.data.status === "false" ||
          response.data.status === "fail"
        ) {
          that.setState({ error: response.data.code });
          console.log("Error: " + response.data.code);
        } else {
          let contacts = response.data.contacts;
          let newArr = [];
          let r = 0;

          for (r = 0; r < response.data.result.length; r++) {
            let attachments = "";
            let events = "";
            let i = 0;

            if (response.data.result[r].docs) {
              for (i = 0; i < response.data.result[r].docs.length; i++) {
                attachments += "(PDF)" + response.data.result[r].docs[i].name + "\n\n";
              }
            }
            if (response.data.result[r].videos) {
              for (i = 0; i < response.data.result[r].videos.length; i++) {
                attachments += response.data.result[r].videos[i].title + "\n\n";
              }
            }
            if (response.data.result[r].events) {
              for (i = 0; i < response.data.result[r].events.length; i++) {
                let eventName = response.data.result[r].events[i].eventName;
                if (response.data.result[r].events[i].email_image_watch) {
                  eventName = "opened";
                }
                events +=
                  eventName +
                  ":\n" +
                  response.data.result[r].events[i].eventDate +
                  "\n \n";
              }
              events = events.slice(0, -2);
            }

            newArr.push({
              dateSent: response.data.result[r].sentDate,
              topic:
                response.data.result[r].template !== ""
                  ? response.data.result[r].template
                  : "[Custom]",
              subject: response.data.result[r].email_subject,
              attachments: attachments,
              recipient:
                response.data.result[r].toName +
                "\n" +
                response.data.result[r].toEmail,
              activity: events,
            });
          }

          // retail segment applied?
          let newActivity = newArr;
          if(that.state.retailSegment !== 0 && that.state.retailSegment !== '0') {
            const searchTerm = "Retail segment: " + that.state.retailSegment;
            newActivity = newArr.filter(
              (activity) =>
                activity["recipient"].includes(searchTerm)
            );
          }

          that.setState({
            activity: newActivity,
            origActivity: newArr,
            segments: response.data.segments,
            isLoading: false,
          });
        }
      })
      .catch(function (response) {
        console.log("error activity");
        console.log(response);

        that.setState({
          isLoading: false,
        });
      });
  };

  handleSearchChange = (e) => {
    const searchTerm = e.target.value.trim();
    if (searchTerm === "") {
      this.setState({ activity: this.state.origActivity });
    } else {
      const results = this.state.origActivity.filter(
        (activity) =>
          activity["recipient"].toLowerCase().includes(searchTerm) ||
          activity["dateSent"].toLowerCase().includes(searchTerm) ||
          activity["topic"].toLowerCase().includes(searchTerm) ||
          activity["subject"].toLowerCase().includes(searchTerm) ||
          activity["attachments"].toLowerCase().includes(searchTerm) ||
          activity["activity"].toLowerCase().includes(searchTerm)
      );
      this.setState({ activity: results });
    }
  };

  render() {
    const columns = [
      {
        Header: "Date Sent",
        accessor: "dateSent",
        sortType: "basic",
        width: 150,
      },
      {
        Header: "Topic",
        accessor: "topic",
        sortType: "basic",
        width: 150,
      },
      {
        Header: "Subject Line",
        accessor: "subject",
        sortType: "basic",
        width: 150,
      },
      {
        Header: "Attachments",
        accessor: "attachments",
        sortType: "basic",
      },
      {
        Header: "Recipient",
        accessor: "recipient",
        sortType: "basic",
        width: 150,
      },
      {
        Header: "Activity",
        accessor: "activity",
        sortType: "basic",
        width: 150,
      },
    ];

    return (
      <div>
        {this.state.isLoading && (
          <div className="activity-loader">
            <Loader
              type="ThreeDots"
              color="#FFD102"
              height={100}
              width={100}
              timeout={300000}
            />
          </div>
        )}

        {!this.state.isLoading && (
          <div>
            <div className="app__profile__activity-actions">
              <DatePicker
                id="activityStartDate"
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={date => this.setState({startDate: date})}
              />
              <span className="divider">-</span>
              <DatePicker
                id="activityEndDate"
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                onChange={date => this.setState({endDate: date})}
              />

              <button
                className="activity-action-submit"
                onClick={() => this.getData()}
              >
                go
              </button>
            </div>
            <div className="app__profile__activity-search">
              <input
                type="text"
                id="activitySearch"
                placeholder="Search..."
                onChange={this.handleSearchChange}
              ></input>
            </div>
            {this.state.activity.length === 0 && 
              <h3>No results</h3>
            }
            {this.state.activity.length > 0 && 
            <Table
              className="app__profile__activity-table"
              columns={columns}
              data={this.state.activity}
            />
            }
          </div>
        )}
      </div>
    );
  }
}

export default ActivityTable;
