import React, { Component } from "react";
import axios from "axios";
import config from "react-global-configuration";
import EmailGrid from "../components/EmailGrid";
import EditTemplate from "../components/EditTemplate";
import Attachments from "../components/Attachments";
import Gallery from "../components/Gallery";
import ISI from "../components/ISI";
import Recipients from "../components/Recipients";
import UserProfile from "../components/UserProfile";
import Modal from "../components/Modal";
import Footer from "../components/Footer";

class PrivateApp extends Component {
  constructor(props) {
    super(props);

    this.childGrid = React.createRef();
    this.childRecipients = React.createRef();
    this.childAttachments = React.createRef();

    this.state = {
      activeSection: "compose",
      activeBrand: 1,
      step: 0,
      selectedTemplateIndex: null,
      templates: [],
      emailGrid: [],
      recipients: [],
      video_ids: [],
      document_ids: [],
      isCustom: false,
      gridFull: false,
      gridDetails: {},
      editDetails: {},
      emailSent: false,
      disableSend: false,
      resetContacts: false,
      pendingSend: false,
      sitePath: 'menu' // 'menu', 'gallery' or 'main'
    };
  }

  componentDidMount = () => {};

  handleLogout = (event) => {
    this.props.onLogout();
  };

  /*
   * Steps
   * 0: Select Topic or Customize
   * 1: Add Attachment (Custom only) (otherwise hidden)
   * 2: Edit Email or Preview (Custom only)
   * 3: Select Recipients
   * */
  handleStepSwitch = (event, newStep) => {
    // this.setState({ step: newStep });
    // Temporarily disabling this as we need to add more logic to handle/enable/disable each option
  };

  handleTemplateClick = (event, id) => {
    //let el = document.getElementById("previewHtml");
    //console.log(el);
    this.setState(
      {
        selectedTemplateIndex: id,
        isCustom: false,
        gridFull: false,
        gridDetails: {},
        activeSection: "compose",
        step: 0,
      },
      () => {
        let el = document.getElementById("previewHtml");
        el.contentDocument.body.innerHTML = this.state.templates[id].body;

        let links = el.contentDocument.body.getElementsByTagName("a");
        for (var i = 0, len = links.length; i < len; i++) {
          links[i].onclick = function () {
            return false;
          };
        }
        el.contentDocument.body.scrollTo(0, 0);
      }
    );
  };

  handleCustomizeClick = (event) => {
    if (this.childGrid.current && this.childGrid.current.callReset) {
      this.childGrid.current.callReset(this.state.activeBrand);
    }

    this.setState(
      {
        selectedTemplateIndex: -1,
        step: 0,
      },
      () => {
        this.setState({ isCustom: true });
      }
    );
  };

  handleSwitchTab = (event, id) => {
    if (
      this.state.activeBrand === id &&
      this.state.activeSection === "compose"
    ) {
      return;
    }

    if (this.childGrid.current && this.childGrid.current.callBrandReload) {
      this.childGrid.current.callBrandReload(id);
    }

    if (
      this.childAttachments.current &&
      this.childAttachments.current.callReset
    ) {
      this.childAttachments.current.callReset(id);
    }

    this.setState(
      {
        activeBrand: id,
        video_ids: [],
        document_ids: [],
      },
      () => {
        if (this.state.activeSection === "profile") {
          this.getTemplates("profile");
        } else {
          this.getTemplates();
        }
      }
    );
  };

  showProfile = (event) => {
    this.setState({ activeSection: "profile" });
  };

  handleGridSelected = (isValid, gridDetails = {}) => {
    this.setState({
      gridFull: isValid,
      gridDetails: gridDetails,
    });
  };

  handleEditOptionsSelected = (editDetails = {}) => {
    this.setState({ editDetails: editDetails });
  };

  handleAttachmentSelected = (type, selected) => {
    if (type === "selectedVideoIds") {
      this.setState({ video_ids: selected });
    } else if (type === "selectedDocIds") {
      this.setState({ document_ids: selected });
    }
  };

  handleRecipientsUpdated = (selectedRecipients) => {
    this.setState({ recipients: selectedRecipients });
  };

  sendEmail = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "sendEmail");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("bID", this.state.activeBrand);
    bodyFormData.set("recipients", JSON.stringify(this.state.recipients));
    let details = {};

    if (this.state.recipients.length === 0 || !this.state.recipients) {
      alert("Please select at least one recipient");
      return;
    }

    if (this.state.pendingSend === true) {
      return;
    }

    this.setState({ disableSend: true, pendingSend: true }, () => {
      // Custom Grid or Template?
      if (this.state.isCustom && this.state.gridFull) {
        bodyFormData.set("hID", "sendEmailGrid");
        bodyFormData.set("apiKey", this.props.user.api_key);

        details = {
          subject: this.state.gridDetails.subject,
          greeting: this.state.gridDetails.init_greet,
          contentA: this.state.gridDetails.contentA,
          contentB: this.state.gridDetails.contentB,
          contentC: this.state.gridDetails.contentC,
          close: this.state.gridDetails.close_email,
          file_ids: this.state.document_ids,
          video_ids: this.state.video_ids,
        };
        bodyFormData.set("details", JSON.stringify(details));
      } else if (!this.state.isCusom && this.state.selectedTemplateIndex >= 0) {
        bodyFormData.set("hID", "sendEmailTemplate");
        bodyFormData.set("apiKey", this.props.user.api_key);

        details = {
          templateName: this.state.templates[this.state.selectedTemplateIndex]
            .topic,
          subject: this.state.editDetails.subject,
          subtext: this.state.editDetails.subtext,
          greeting: this.state.editDetails.greeting,
          intro: this.state.editDetails.intro,
          close: this.state.editDetails.close,
        };

        bodyFormData.set("details", JSON.stringify(details));
        bodyFormData.set(
          "tID",
          this.state.templates[this.state.selectedTemplateIndex].id
        );
      } else {
        console.log("Something wrong with sending");
        return;
      }

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          if(response.data.status === "success") {
            that.setState({ emailSent: true, pendingSend: false });
          }
        })
        .catch(function (response) {
          that.setState({ disableSend: true });
        });
    });
  };

  handleNext = (event) => {
    if (this.state.step === 3 && !event.target.classList.contains("disabled")) {
      this.sendEmail();
      return;
    }

    if (this.state.pendingSend) {
      return;
    }

    let newStep = this.state.step + 1;
    if (newStep === 1 && !this.state.isCustom) {
      // skip attachments
      newStep = 2;
      this.setState({ resetContacts: true });
      // if (
      //   this.childRecipients.current &&
      //   this.childRecipients.current.callReset
      // ) {
      //   this.childRecipients.current.callReset();
      // }
    }

    this.setState({ step: newStep });

    switch (newStep) {
      case 2:
        if (!this.state.isCustom) {
          // EditTemplate will take care of this, do nothing
        } else {
          this.previewCustomEmail();
        }
        break;
      case 3:
        // if (
        //   this.childRecipients.current &&
        //   this.childRecipients.current.callReset
        // ) {
        //   this.childRecipients.current.callReset();
        // }
        break;
      default:
        break;
    }
  };

  handleProfileSendEmail = () => {
    this.handlePath('main');
  }

  handlePrevious = (event) => {
    if (this.state.activeSection === "profile") {
      this.setState(
        {
          activeSection: "compose",
          step: 0,
          custom: false,
          selectedTemplateIndex: 0,
          gridFull: false,
          gridDetails: {},
        },
        () => {
          this.handleTemplateClick(null, 0);
        }
      );
    }

    if (this.state.step >= 0) {
      let newStep = this.state.step - 1;
      if (newStep === 1 && !this.state.isCustom) {
        // skip attachments
        newStep = 0;
      }
      this.setState({ step: newStep }, () => {
        if (
          this.childAttachments.current &&
          this.childAttachments.current.callReselectAttachments
        ) {
          this.childAttachments.current.callReselectAttachments(
            this.state.document_ids,
            this.state.video_ids
          );
        }
      });
    }
  };

  getTemplates = (setSection = "compose") => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getQsTemplates");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("bID", this.state.activeBrand);
    bodyFormData.set("apiKey", this.props.user.api_key);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        that.setState(
          {
            templates: response.data.result,
            activeSection: setSection,
            step: 0,
            custom: false,
            selectedTemplateIndex: -1,
            gridFull: false,
            gridDetails: {},
          },
          () => {
            if(setSection !== "profile") {
              that.handleTemplateClick(null, 0);
            }

        that.setState({ isCustom: true });
    
          }
        );
      })
      .catch(function (response) {
        console.log("Error getting templates");
      });
  };

  handleUserUpdated = (newDetails) => {
    this.props.handleUserUpdated(newDetails);
  };

  previewCustomEmail = () => {
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "previewGridEmail");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("bID", this.state.activeBrand);
    bodyFormData.set("apiKey", this.props.user.api_key);

    let details = {
      subject: this.state.gridDetails.subject,
      greeting: this.state.gridDetails.init_greet,
      contentA: this.state.gridDetails.contentA,
      contentB: this.state.gridDetails.contentB,
      contentC: this.state.gridDetails.contentC,
      close: this.state.gridDetails.close_email,
      file_ids: this.state.document_ids,
      video_ids: this.state.video_ids,
      repName: this.props.user.name,
    };
    bodyFormData.set("details", JSON.stringify(details));

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        let el = document.getElementById("previewHtmlCustom");
        el.contentDocument.body.innerHTML = response.data.result;

        let links = el.contentDocument.body.getElementsByTagName("a");
        for (var i = 0, len = links.length; i < len; i++) {
          links[i].onclick = function () {
            return false;
          };
        }

        el.style.height =
          el.contentWindow.document.documentElement.scrollHeight + "px";
        el.contentDocument.body.scrollTo(0, 0);
      })
      .catch(function (response) {
        console.log("Error previewing grid email");
      });
  };

  handleModalViewProfile = () => {
    this.setState({
      emailSent: false,
      activeSection: "profile",
      disableSend: false,
    });
    this.childRecipients.current.callReset();
  };

  handleModalNewEmail = () => {
    this.setState({
      emailSent: false,
      disableSend: false,
      activeSection: "compose",
      step: 0,
      custom: false,
      selectedTemplateIndex: 0,
    });
    this.childRecipients.current.callReset();
    this.handleTemplateClick(null, 0);
  };

  handlePath = (v) => {
    if(v == 'main') {
      this.getTemplates();
    }
    this.setState({'sitePath' : v, 'activeSection' : 'compose'});
  }

  render() {
    let n = this.props.user.name;
    let fn = n.substring(0, n.indexOf(' '));
    let ln = n.substring(n.indexOf(' ') + 1);

    if (
          this.childAttachments.current &&
          this.childAttachments.current.callReselectAttachments
        ) {
          this.childAttachments.current.callReselectAttachments(
            this.state.document_ids,
            this.state.video_ids
          );
        }
    return (
      <div
        className={`app ${
          this.state.activeBrand === 1 ? "skin1" : this.state.activeBrand === 2 ? "skin2" : "skin3"
        }`}
      >
        
        <div className="landscapeOverlay">
          <p>Small mobile devices are not supported.</p>
        </div>
        <div
          className={`${
            this.state.emailSent || this.state.pendingSend ? "" : "hidden"
          }`}
        >
          <Modal
            isPending={this.state.pendingSend}
            handleViewProfile={this.handleModalViewProfile}
            handleNewEmail={this.handleModalNewEmail}
          />
        </div>
        {this.state.sitePath=='main' && 
        <div className={`app__pagenavigation clearfix`}>
          <div
            className={`app__pagenavigation__icon prev ${
              this.state.step !== 0 || this.state.activeSection === "profile"
                ? ""
                : "hidden"
            }`}
            onClick={(event) => this.handlePrevious(event)}
          ><i className="fa fa-angle-left"></i></div>
          <div
            className={`app__pagenavigation__icon next ${
              (!this.state.gridFull && this.state.isCustom) ||
              this.state.activeSection === "profile" || (this.state.step === 1 && this.state.video_ids.length === 0 && this.state.document_ids.length === 0)
                ? "hidden"
                : ""
            } ${this.state.step === 3 ? "send" : ""} ${
              this.state.disableSend ? "disabled" : ""
            }`}
            onClick={(event) => this.handleNext(event)}
          ><i className="fa fa-angle-left"></i></div>
        </div>
        }
        <header className="app__header">
          <div className="app__header__container">
            <div className="app__logo"></div>
            <div className="app__header__profile">
              <span className="app__header__username">
              <span>Welcome,</span><br/>
                {fn}<br/>{ln}
              </span>
              <div
                id="profileImage"
                className="app__icon app__header__icon"
                onClick={this.showProfile}
                style={{ backgroundImage: `url(${this.props.user.photo})` }}
              ></div>
            </div>
            <div className="app__header__tabs">
              <div
                className="app__header__tab"
                onClick={(event) => this.handleSwitchTab(event, 3)}
              ></div>
              <div
                className="app__header__tab"
                onClick={(event) => this.handleSwitchTab(event, 2)}
              ></div>
              <div
                className="app__header__tab"
                onClick={(event) => this.handleSwitchTab(event, 1)}
              ></div>
            </div>
          </div>
        </header>
        {this.state.sitePath=='menu' && 
        <div className="container">
        {this.state.activeSection !== "profile" && (
          <section className="app__init-menu">
            <h3 className="app__block-heading">Main Menu</h3>
            <div className="init-menu__container">
            <div className="init-menu__item" onClick={() => this.handlePath('gallery')}> 
            <img src="/img/media_icon.png"/><br/>
            REVIEW MEDIA</div>
            <div className="init-menu__item" onClick={() => this.handlePath('main')}>
            <img src="/img/email_icon.png"/><br/>
            CREATE EMAIL</div>

            </div>
          
          </section>
          )}
          {this.state.activeSection === "profile" && (
              <UserProfile
                user={this.props.user}
                onLogout={this.props.onLogout}
                bid={this.state.activeBrand}
                handleUserUpdated={this.handleUserUpdated}
                handleSendEmailClick={this.handleProfileSendEmail}
                handlePath={this.handlePath}
              />
            )}
            </div>
      }
      {this.state.sitePath=='gallery' && 
      <div>
      {this.state.activeSection !== "profile" && (
        <Gallery  
                      ref={this.childAttachments}
                      uid={this.props.user.id}
                      bid={this.state.activeBrand}
                      user={this.props.user}
                      handleSelected={this.handleAttachmentSelected}
                      meow={this.childAttachments}
                      handlePath = {this.handlePath}

                    ></Gallery>
                    )}
                    {this.state.activeSection === "profile" && (
                    <div className="app__container">
              <UserProfile
                user={this.props.user}
                onLogout={this.props.onLogout}
                bid={this.state.activeBrand}
                handleUserUpdated={this.handleUserUpdated}
                handleSendEmailClick={this.handleProfileSendEmail}
                handlePath={this.handlePath}
              />
              </div>
            )}
                    </div>
                  }
      {this.state.sitePath=='main' && 
        <div className="app__container">
          <div>
            {this.state.activeSection === "compose" && (
              <div>
              <h3 className="app__block-heading"><span className="fake-link" onClick={() => this.handlePath('menu')}>Home</span> <i className="fa fa-chevron-right"></i> Create Email</h3>
                <section className="app__subheader">
                  <nav className="app__subheader__nav" aria-label="Breadcrumb">
                    <ul className="app__subheader__list">
                      <li
                        key="step-1"
                        className={`app__subheader__crumb ${
                          this.state.step === 0
                            ? "app__subheader__crumb--selected"
                            : "meow"
                        }`}
                        onClick={(event) => this.handleStepSwitch(event, 0)}
                      >
                        Select Email
                      </li>
                      <li
                        key="step-2"
                        className={`app__subheader__crumb ${
                          this.state.step === 1
                            ? "app__subheader__crumb--selected"
                            : ""
                        } ${this.state.isCustom ? "" : "hidden"}`}
                      >
                        <span
                          onClick={(event) => this.handleStepSwitch(event, 1)}
                        >
                          Add Attachments
                        </span>
                      </li>
                      <li
                        key="step-3"
                        className={`app__subheader__crumb ${
                          this.state.step === 2
                            ? "app__subheader__crumb--selected"
                            : ""
                        }`}
                        onClick={(event) => this.handleStepSwitch(event, 2)}
                      >
                        {this.state.isCustom ? "Preview Email" : "Preview Email"}
                      </li>
                      <li
                        key="step-4"
                        className={`app__subheader__crumb ${
                          this.state.step === 3
                            ? "app__subheader__crumb--selected"
                            : ""
                        }`}
                      >
                        <span
                          onClick={(event) => this.handleStepSwitch(event, 3)}
                        >
                        Select Recipients
                        </span>
                      </li>
                    </ul>
                  </nav>
                </section>

                <div className="composerContainer">
                  <div
                    className={`templateSelectContainer ${
                      this.state.step === 0 ? "" : "hidden"
                    }`}
                  >
                  <p>Customize your email or select your email template below.</p>
                    <nav
                      className={`app__main-tabs ${
                        this.state.step === 0 && this.state.templates.length > 0
                          ? ""
                          : "hidden"
                      }`}
                      aria-label="Tabs"
                    >
                      <ul className="app__main-tabs__list">
                      {this.state.activeBrand !== 3 && (
                        <li
                          className={`app__main-tabs__tab ${
                            this.state.isCustom
                              ? "app__main-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={(event) => this.handleCustomizeClick(event)}
                          key={"topic-custom"}
                        >
                          Customize
                        </li>
                        )}
                        {this.state.templates.map((row, key) => (
                          <li
                            className={`app__main-tabs__tab ${
                              (this.state.selectedTemplateIndex === key && !this.state.isCustom)
                                ? "app__main-tabs__tab--selected"
                                : ""
                            }`}
                            onClick={(event) =>
                              this.handleTemplateClick(event, key)
                            }
                            key={"topic-" + key}
                          >
                            {row.topic}
                          </li>
                        ))}
                      </ul>
                    </nav>

                    <div
                      className={`templateContainer ${
                        !this.state.isCustom ? "" : "hidden"
                      }`}
                    >
                      <iframe
                        title="Preview"
                        className="templatePreview"
                        id="previewHtml"
                      ></iframe>
                    </div>

                    <div
                      id="emailGridContainerParent"
                      className={`app__flex-table ${
                        this.state.isCustom ? "" : "hidden"
                      }`}
                    >
                      <EmailGrid
                        ref={this.childGrid}
                        uid={this.props.user.id}
                        bid={this.state.activeBrand}
                        user={this.props.user}
                        handleGridSelected={this.handleGridSelected}
                      ></EmailGrid>
                    </div>
                  </div>

                  <div
                    id="attachmentsContainer"
                    className={`attachmentsContainer ${
                      this.state.step === 1 ? "" : "hidden"
                    }`}
                  >
                    <Attachments
                      ref={this.childAttachments}
                      uid={this.props.user.id}
                      user={this.props.user}
                      bid={this.state.activeBrand}
                      step={this.state.step}
                      subject={this.state.gridDetails.subject}
                      handleSelected={this.handleAttachmentSelected}
                    ></Attachments>
                  </div>

                  <div
                    className={`templateEditContainer ${
                      this.state.step === 2 &&
                      !this.state.isCustom &&
                      this.state.selectedTemplateIndex !== null
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <nav
                      className={`app__main-tabs ${
                        this.state.templates.length > 0 ? "" : "hidden"
                      }`}
                      aria-label="Tabs"
                    >
                      <ul className="app__main-tabs__list">
                        {this.state.templates.map((row, key) => (
                          <li
                            className={`app__main-tabs__tab ${
                              this.state.selectedTemplateIndex === key
                                ? "app__main-tabs__tab--selected"
                                : ""
                            }`}
                            onClick={(event) =>
                              this.handleTemplateClick(event, key)
                            }
                            key={"topic-" + key}
                          >
                            {row.topic}
                          </li>
                        ))}
                        {this.state.activeBrand !== 3 && (
                        <li
                          className={`app__main-tabs__tab ${
                            this.state.isCustom
                              ? "app__main-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={(event) => this.handleCustomizeClick(event)}
                          key={"topic-custom"}
                        >
                          Customize
                        </li>
                        )}
                      </ul>
                    </nav>

                    <div className="templateContainer">
                      <EditTemplate
                        user={this.props.user}
                        bid={this.state.activeBrand}
                        templates={this.state.templates}
                        activeTemplateIndex={this.state.selectedTemplateIndex}
                        handleOptionChange={this.handleEditOptionsSelected}
                      ></EditTemplate>
                    </div>
                  </div>

                  <div
                    className={`templateEditContainer ${
                      this.state.step === 2 && this.state.isCustom
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <div className="templateContainer">
                      <p className="previewSubject">
                        <i className="fa fa-envelope"></i> Subject: <span>{this.state.gridDetails.subject}</span>
                      </p>
                      <iframe
                        title="Preview"
                        className="templatePreview"
                        id="previewHtmlCustom"
                      ></iframe>
                    </div>
                  </div>

                  <div
                    id="recipientsContainer"
                    className={`selectRecipientsContainer ${
                      this.state.step === 3 ? "" : "hidden"
                    }`}
                  >
                    <Recipients
                      ref={this.childRecipients}
                      sendMode={true}
                      profileMode={false}
                      doReset={this.state.resetContacts}
                      uid={this.props.user.id}
                      user={this.props.user}
                      bid={this.state.activeBrand}
                      handleRecipientsUpdated={this.handleRecipientsUpdated}
                    ></Recipients>
                  </div>
                </div>
              </div>
            )}

            {this.state.activeSection === "profile" && (
              <UserProfile
                user={this.props.user}
                onLogout={this.props.onLogout}
                bid={this.state.activeBrand}
                handleUserUpdated={this.handleUserUpdated}
                handleSendEmailClick={this.handleProfileSendEmail}
                handlePath={this.handlePath}
              />
            )}
          </div>
        </div>
      }

        <Footer />
      </div>
    );
  }
}

export default PrivateApp;
