import React, { Component } from "react";
import config from "react-global-configuration";
import ActivityTable from "../components/ActivityTable";
import Recipients from "../components/Recipients";
import axios from "axios";

const validPhone = (phone) => {
  const phoneRegex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
  if(!phone.match(phoneRegex)) {
    return false;
  }
  return true;
}

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.toggleInputs = this.toggleInputs.bind(this);
    this.state = {
      error: "",
      updateError: "",
      showInputs: true,
      showPassword: false,
      activeSection: "activity", // contacts,
    };
  }

  componentDidMount = () => {
    // Set up form data
    document.getElementById("userName").value = this.props.user.name;
    document.getElementById("userTitle").value = this.props.user.title;
    document.getElementById("userPhone").value = this.props.user.phone;
    document.getElementById("userEmail").value = this.props.user.email;
  };

  toggleInputs() {
    this.setState({ showInputs: !this.state.showInputs });
  }

  handleRecipientsUpdated = () => {}

  handleLogout = (event) => {
    this.props.onLogout();
  };

  handleShowSection = (name) => {
    this.setState({ activeSection: name });
  };

  handleUpdateUser = (event) => {
    let name = document.getElementById("userName").value;
    let phone = document.getElementById("userPhone").value;

    if (name === "" || phone === "") {
      this.setState({ updateError: "All fields are required." });
    } else if(!validPhone(phone)) {
      this.setState({ updateError: "Phone number is invalid." });
    } else {
      let that = this;
      let apiUrl = config.get("apiUrl");
      let bodyFormData = new FormData();
      bodyFormData.set("hID", "updateUser");
      bodyFormData.set("uID", this.props.user.id);
      bodyFormData.set("fullname", name);
      bodyFormData.set("phone", phone);
      bodyFormData.set("apiKey", this.props.user.api_key);

      let newDetails = {
        fullname: name,
        phone: phone,
      };

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          if (
            response.data.status === "false" ||
            response.data.status === "fail"
          ) {
            that.setState({ updateError: response.data.code });
          } else {
            that.setState({ updateError: "User successfully updated" });
            that.props.handleUserUpdated(newDetails);
          }
        })
        .catch(function (response) {
          that.setState({ updateError: "Error updating user" });
        });
    }
  };

  validPassword = (pw) => {
    // 6-12 characters, containing at least one numeric digit and a special character
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/;
    if(!pw.match(passwordRegex)) {
      return false;
    }
    return true;
  }

  handleChangePassword = (event) => {
    let oldPw = document.getElementById("oldPassword").value.trim();
    let newPw = document.getElementById("newPassword").value.trim();
    let newPw2 = document.getElementById("newPasswordRepeat").value.trim();

    if (oldPw === "" || newPw === "" || newPw2 === "") {
      this.setState({ error: "All password fields are required." });
    } else if (newPw !== newPw2) {
      this.setState({ error: "Repeated new password does not match." });
    } else if(!this.validPassword(newPw)) {
      this.setState({
        error: "Password must be 6-12 characters, containing at least one numeric digit and a special character",
      });
    } else {
      let that = this;
      let apiUrl = config.get("apiUrl");
      let bodyFormData = new FormData();
      bodyFormData.set("hID", "changePass");
      bodyFormData.set("uID", this.props.user.id);
      bodyFormData.set("oldPw", oldPw);
      bodyFormData.set("newPw", newPw2);
      bodyFormData.set("apiKey", this.props.user.api_key);

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          if (
            response.data.status === "false" ||
            response.data.status === "fail"
          ) {
            that.setState({ error: response.data.code });
          } else {
            that.setState({ error: "Password successfully updated." });
          }
        })
        .catch(function (response) {
          that.setState({ error: "Error updating password" });
        });
    }
  };

  togglePassword = () => {
    let x = !this.state.showPassword;
    this.setState({ showPassword: x });
  };

  render() {
    let toggleClass = this.state.showInputs
      ? "app__password__form"
      : "app__password__form app__password__form--hidden";

    return (
      <section className="app__profile">
      <h3 className="app__block-heading"><span className="fake-link" onClick={() => this.props.handlePath('menu')}>Home</span> <i className="fa fa-chevron-right"></i> User Profile</h3>
        <div className="app__profile__top-area clearfix">
        
          <div
            className="app__profile__big-icon"
            style={{ backgroundImage: `url(${this.props.user.photo})` }}
          ></div>
          <div className="app__profile__error-div app__profile__error-div--top">
            {this.state.updateError}
          </div>
          <div className="app__profile__inputlist">
            <label className="app__profile__input-text__label clearfix">
              <div className="app__profile__input-text__label-text">Name</div>
              <input
                className="app__profile__input-text app__profile__input-text--alt"
                type="text"
                name="userName"
                id="userName"
              ></input>
            </label>
            <label className="app__profile__input-text__label clearfix">
              <div className="app__profile__input-text__label-text">Phone</div>
              <input
                className="app__profile__input-text app__profile__input-text--alt"
                type="text"
                name="userPhone"
                id="userPhone"
              ></input>
            </label>
            <label className="app__profile__input-text__label clearfix">
              <div className="app__profile__input-text__label-text" disabled>
                Title
              </div>
              <input
                className="app__profile__input-text app__profile__input-text--alt"
                type="text"
                name="userTitle"
                id="userTitle"
                disabled
              ></input>
            </label>
            <label className="app__profile__input-text__label clearfix">
              <div className="app__profile__input-text__label-text" disabled>
                Email
              </div>
              <input
                className="app__profile__input-text app__profile__input-text--alt"
                type="text"
                name="userEmail"
                id="userEmail"
                disabled
              ></input>
            </label>
            <button
              className="app__profile__update"
              onClick={(event) => this.handleUpdateUser()}
            >
              update
            </button>
          </div>

          <div className="app__profile__button-container">
            <button
              className={`app__profile__button ${
                this.state.activeSection === "activity" ? "active" : ""
              }`}
              onClick={(event) => this.handleShowSection("activity")}
            >
              View Activity
            </button>
            <button
              className={`app__profile__button ${
                this.state.activeSection === "contacts" ? "active" : ""
              }`}
              onClick={(event) => this.handleShowSection("contacts")}
            >
              Update Contacts
            </button>
            <button className="app__profile__button" onClick={this.togglePassword}>Change Password</button>
            <button className="app__profile__button" onClick={(event) => this.props.handleSendEmailClick()}>Send an Email</button>
            <button className="app__profile__button" onClick = {(event) => this.handleLogout()}>Log Out</button>
          </div>
        </div>
        <div className="app__profile__bottom-area">
        {this.state.showPassword &&
          <div>
          <div className={toggleClass}>
            <div className="app__profile__error-div--btm">{this.state.error}</div>
            <label className="app__profile__password-label">
              <input
                className="app__profile__password-input"
                type="password"
                name="oldPassword"
                id="oldPassword"
              ></input>
              <p>Old Password</p>
            </label>
            <label className="app__profile__password-label">
              <input
                className="app__profile__password-input"
                type="password"
                name="newPassword"
                id="newPassword"
              ></input>
              <p>New Password</p>
            </label>
            <label className="app__profile__password-label">
              <input
                className="app__profile__password-input"
                type="password"
                name="newPasswordRepeat"
                id="newPasswordRepeat"
              ></input>
              <p>Confirm Password</p>
            </label>
            <button
              className="app__profile__ok button"
              onClick={(event) => this.handleChangePassword()}
            >
              OK
            </button>
          </div>
          </div>
        }
          <div
            className="app__profile__activity-container"
            style={{
              display:
                this.state.activeSection === "activity" ? "block" : "none",
            }}
          >
            <h4 className="app__profile__table-heading">Activity Log</h4>
            <div className="app__profile__activity-table userActivity">
              <ActivityTable user={this.props.user}></ActivityTable>
            </div>
          </div>

          {(this.state.activeSection === "contacts" &&
            <div
              className="app__profile__contacts-container"
              style={{
                display:
                  this.state.activeSection === "contacts" ? "block" : "none",
              }}
            >
              <div className="app__profile__contacts">
                <Recipients
                  profileMode={true}
                  sendMode={false}
                  doReset={true}
                  user={this.props.user}
                  uid={this.props.user.id}
                  bid={this.props.bid}
                  handleRecipientsUpdated={this.handleRecipientsUpdated}
                ></Recipients>
              </div>
            </div>
          )}

        </div>
      </section>
    );
  }
}

export default UserProfile;
