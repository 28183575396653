import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import ContactModal from "../components/ContactModal";
import AddContactModal from "../components/AddContactModal";
import CustomSelect from "../components/CustomSelect";
import OutlookConnector from '../components/OutlookConnector';

class Recipients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      origContacts: [],
      selectedContacts: [],
      searchTerm: "",
      allSelected: false,
      sortDir: "asc",
      didReset: true,
      showContactCardModal: true,
      showAddContactModal: false,
      showWarningModal: false,
      sendView: 'all', // all or distribution
      editingContact: null,
      distributionName: '',
      distributionView: 'save', // save or view
      distributionGroups: [],
      selectedDistributionGroups: [],
      showDistributionWarningModal: false,
      showDistributionDeleteModal: false,
      showDistributionCreatedModal: false,
      groupToDelete: null, // id
      groupObjectToEdit: null, // full object
      showClearSelectionsModal: false
    };
  }

  callReset() {
    this.getContacts();
  }

  componentDidMount = () => {
    if(this.state.selectedContacts.length === 0) {
      this.getContacts();
    }
  };
  
  getContacts = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getOutlookContacts");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("apiKey", this.props.user.api_key);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (response.data.status === 'success') {
          that.setState({
            contacts: response.data.result,
            origContacts: response.data.result,
            doReset: false,
            selectedContacts: [],
            allSelected: false,
            sendView: 'all',
          });

          let i =0;
          response.data.result.forEach((contact) => {
            document.getElementById("contact-" + i).checked = false;
            i = i + 1;
          });
        } else {
          console.log("Error getting contacts");
        }
      })
      .catch(function (response) {
        console.log("Error getting contacts");
      });
  };

  handleSelect = (event, index, type) => {
    let selected = [...this.state.selectedContacts];
    let contacts = [...this.state.contacts];

    // Select Contact
    if (type === "contact") {
      if (event.target.checked) {
        selected.push(this.state.contacts[index]);
      } else {
        selected = selected.filter(
          (selected) => selected !== this.state.contacts[index]
        );
      }

      this.setState({ selectedContacts: selected, contacts: contacts });
      // Update parent
      this.props.handleRecipientsUpdated(selected);
    }
  };

  selectAll = (event) => {
    let selected = [];
    let contacts = [...this.state.contacts];
    let i = 0;

    if (!this.state.allSelected) {
      contacts.forEach((contact) => {
        if (!document.getElementById("contact-" + i).disabled) {
          contact.toEmail = contact.email;
          selected.push(contact);
          document.getElementById("contact-" + i).checked = true;
        }
        i = i + 1;
      });
      this.setState({ selectedContacts: selected, allSelected: true });
    } else {
      // Deselect and reset any group actions
      contacts.forEach((contact) => {
        document.getElementById("contact-" + i).checked = false;
        i = i + 1;
      });
      this.setState({
        selectedContacts: selected,
        allSelected: false,
      });
    }
    // Update parent
    this.props.handleRecipientsUpdated(selected);
  };

  sortArray = (event, type) => {
    const types = {
      firstName: "firstName",
      lastName: "lastName",
      practice: "practice",
      city: "city",
      state: "state",
      zip: "zip",
    };

    let sorted;
    let newSortDir = "desc";
    const sortProperty = types[type];

    if (this.state.sortDir === "asc") {
      sorted = this.state.contacts.sort((a, b) =>
        a[sortProperty] > b[sortProperty] ? 1 : -1
      );
    } else {
      sorted = this.state.contacts.sort((a, b) =>
        a[sortProperty] > b[sortProperty] ? -1 : 1
      );
      newSortDir = "asc";
    }

    // Update selections
    this.setState(
      {
        contacts: sorted, sortDir: newSortDir
      },
      () => {
        this.recheckContacts();
      }
    );
  };

  recheckContacts = () => {
    // Ensure inputs of selected are re-checked
    let i = 0;
    this.state.contacts.forEach((contact) => {
      let contactSelected = this.state.selectedContacts.filter(function (
        item
      ) {
        if (item["id"] === contact["id"]) {
          return true;
        }
        return false;
      });
      document.getElementById("contact-" + i).checked =
        contactSelected.length > 0;

      i = i + 1;
    });
  }

  handleSearchKeyUp = (event) => {
    let searchVal = event.currentTarget.value;
    let filter = {
      firstName: searchVal,
      lastName: searchVal,
      practice: searchVal,
      email: searchVal,
      city: searchVal,
      state: searchVal,
      zip: searchVal,
    };

    if (searchVal.trim() === "") {
      this.setState(
        {
          contacts: this.state.origContacts,
        },
        () => {
          this.recheckContacts();
        }
      );
      return;
    }

    let contacts = this.state.origContacts.filter(function (item) {
      for (var key in filter) {
        if (item[key].toLowerCase().indexOf(filter[key].toLowerCase()) >= 0) {
          return true;
        }
      }
      return false;
    });

    this.setState(
      {
        contacts: contacts,
      },
      () => {
        // Ensure inputs of selected are re-checked
        let i = 0;
        this.state.contacts.forEach((contact) => {
          let contactSelected = this.state.selectedContacts.filter(function (
            item
          ) {
            if (item["id"] === contact["id"]) {
              return true;
            }
            return false;
          });
          document.getElementById("contact-" + i).checked = contactSelected.length > 0;

          i = i + 1;
        });
      }
    );
  };

  closeContactCardModal = (didSave, didUpdateRecipientList, updatedContact) => {
    let newContacts = [...this.state.origContacts];
    let selected = [...this.state.selectedContacts];
    const selectedIndex = selected.findIndex((e) => e.id === updatedContact.id);
    if (didSave || didUpdateRecipientList) {
      const index = newContacts.findIndex((e) => e.id === updatedContact.id);
      newContacts[index] = updatedContact;

      if (selectedIndex >= 0) {
        selected[selectedIndex] = updatedContact;
      }

      // If the user had called a Save action on the Contact Card, warn of DB overwrites
      this.setState({
        showContactCardModal: false,
        showWarningModal: didSave,
        contacts: newContacts,
        origContacts: newContacts,
        selectedContacts: selected,
      });

      // Update parent of selected contact changes
      this.props.handleRecipientsUpdated(selected);
    } else {
      this.setState({
        showContactCardModal: false,
        showWarningModal: didSave
      });
    }
  };

  clickAllPrimary = (e) => {
    // TODO: reset all dropdowns to select primary email address
    this.selectAll(null);
  };

  selectDropdownEmail = (newToEmail, contact) => {
    if (contact.toEmail !== newToEmail) {
      let updatedSelected = [...this.state.selectedContacts];
      const selectedIndex = updatedSelected.findIndex(
        (e) => e.id === contact.id
      );
      if(selectedIndex !== -1) {
        updatedSelected[selectedIndex].toEmail = newToEmail;
        this.setState({ selectedContacts: updatedSelected });
      } else {
        let updatedContacts = [...this.state.contacts];
        const contactIndex = updatedContacts.findIndex(
          (e) => e.id === contact.id
        );
        updatedContacts[contactIndex].toEmail = newToEmail;
        this.setState({ contacts: updatedContacts });
      }
      
    }
  };

  updateContacts = (contacts) => {
    this.setState({
      contacts: contacts,
      origContacts: contacts,
      doReset: false,
      selectedContacts: [],
      allSelected: false,
      sendView: 'all',
      showAddContactModal: false
    });
  }

  render() {
    const isGroupSelected = this.state.allSelected;

    const renderEmailSelect = (row, key) => {
      let isDisabled = row.unsubscribed === true || row.email === "";

      let emails = [row.email];
      if (row.hcp_email_1 !== "") {
        emails.push(row.hcp_email_1);
      }
      if (row.hcp_email_2 !== "") {
        emails.push(row.hcp_email_2);
      }
      if (row.hcp_email_3 !== "") {
        emails.push(row.hcp_email_3);
      }
      if (row.hcp_email_4 !== "") {
        emails.push(row.hcp_email_4);
      }

      return (

        <CustomSelect
          type="recipientEmail"
          options={emails}
          onSelect={this.selectDropdownEmail}
          isDisabled={!this.props.profileMode && (isDisabled)}
          contact={row}
        />
      );
    };

    const renderRowInput = (
      unsubscribed,
      email,
      key,
      type
    ) => {
      if (unsubscribed === true) {
        return (
          <input
            type="checkbox"
            disabled
            className="checkbox-square has-optout"
            id={type + "-" + key}
          />
        );
      } else if (email === "") {
        return (
          <input
            type="checkbox"
            disabled
            className="checkbox-square is-empty"
            id={type + "-" + key}
          />
        );
      } else {
        return (
          <input
            onClick={(event) => this.handleSelect(event, key, type)}
            type="checkbox"
            className="checkbox-square"
            id={type + "-" + key}
          />
        );
      }
    };

    return (
      <section className="app__recipients">
        {this.state.showContactCardModal &&
          this.state.editingContact !== null && (
            <ContactModal
              uid={this.props.uid}
              user={this.props.user}
              handleClose={this.closeContactCardModal}
              contact={this.state.editingContact}
              profileMode={this.props.profileMode ? true : false}
            />
          )}

          {this.state.showAddContactModal && (
            <AddContactModal
              uid={this.props.uid}
              user={this.props.user}
              onUpdateContacts={this.updateContacts} 
              handleClose={() => {this.setState({showAddContactModal: false})}}
            />
          )}

        <div className="app__recipients__container">
          <div className='app__recipients-import'>
            <button className='app__recipients__connect-btn app__button app__button--secondary' 
              onClick={() => this.setState({showAddContactModal: true})}>
              Add Contact
            </button>
            <OutlookConnector onUpdateContacts={this.updateContacts} userID={this.props.uid} user={this.props.user}/>
          </div>
          <p>Please select your mail recipients from the list below. Double-clicking a selected recipient will allow you to edit them.</p>
          <div
            className="app__recipients__search"
            style={{ minHeight: "auto" }}
          >

            <h4 className="app__recipients__heading">Search</h4>
            <input type="text" className="app__recipients-input" placeholder="[Dr. First Name, Last Name] ; [Dr. First Name, Last Name]" onChange={this.handleSearchKeyUp}></input>
            {this.props.sendMode && 
            <div
              className="app__recipients-selected">
              <div className="app__recipients-selected__title">
                Selected Recipients ({this.state.selectedContacts.length})
              </div>
              <div
                className="app__recipients-selected__list"
                id="selectedContacts"
              >
                {this.state.selectedContacts.map((row, key) => (
                  <span key={"contact-" + key}>{row.toEmail}, </span>
                ))}
              </div>
            </div>
            }

            {this.props.sendMode &&
              <div>
                <div className="app__recipients-dist-send-actions">
                  <button className={`${this.state.sendView === 'all' ? "active" : ""}`} onClick={(e) => this.setState({ showClearSelectionsModal: true })}>All Contacts</button> |
                  <button className={`${this.state.sendView === 'distribution' ? "active" : ""}`} onClick={(e) => this.setState({ showClearSelectionsModal: true })}>Distribution Groups</button>
                </div>
              </div>
            }
          </div>

          <div className="app__recipients__list">
            <div className="app__recipients__commands">
              <img className="commands__close" src="/img/close.svg" alt="-" />
              {this.props.sendMode && this.state.sendView === "all" &&
                <div className="section-1">
                  <span className="subtitle">HCP Group Selections</span>
                  <div>
                    <button
                      onClick={(e) => this.clickAllPrimary()}
                      className={`${this.state.allSelected ? "active" : ""}`}
                    >
                      All Primary
                    </button>
                  </div>
                </div>
              }
            </div>

            {((this.props.sendMode && this.state.sendView === 'all') || this.props.profileMode) && (
              <>
                <table className="app__recipients__list-table recipient-dist-table">

                  <tbody>
                    <tr
                      style={{ display: this.props.profileMode ? "none" : "block" }}
                    >
                      <th
                        colSpan="9"
                        className="select_all"
                      >
                        <span onClick={(e) => this.selectAll()}>
                          {this.state.allSelected === true
                            ? "Deselect All"
                            : "Select All"}
                        </span>&nbsp;|&nbsp;
                        <span className="sort_by">Sort by column</span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          display: this.props.profileMode ? "none" : "block",
                        }}
                      ></th>
                      <th
                        className="asc"
                        onClick={(event) => this.sortArray(event, "firstName")}
                      >
                        <span>F Name</span>
                      </th>
                      <th onClick={(event) => this.sortArray(event, "lastName")}>
                        <span>L Name</span>
                      </th>
                      <th onClick={(event) => this.sortArray(event, "practice")}>
                        <span>Specialty</span>
                      </th>
                      <th>
                        <span>Email Address</span>
                      </th>
                      <th onClick={(event) => this.sortArray(event, "city")}>
                        <span>City</span>
                      </th>
                      <th onClick={(event) => this.sortArray(event, "state")}>
                        <span>State</span>
                      </th>
                      <th onClick={(event) => this.sortArray(event, "zip")}>
                        <span>Zip</span>
                      </th>
                    </tr>
                    {this.state.contacts.map((row, key) => (
                      <tr
                        key={key}
                        className={`${row.unsubscribed
                          ? "has-optout"
                          : row.unsubscribedNpi ? "has-optout-npi"
                            : row.email === ""
                              ? "is-empty"
                              : ""
                          }`}
                        onDoubleClick={(event) => {

                          let selected = [...this.state.selectedContacts];
                          const selectedIndex = selected.findIndex(
                            (e) => row.id === e.id
                          );
                          if (selectedIndex >= 0 || this.props.profileMode) {
                            if (row.toEmail !== row.email) {
                              alert(
                                "Cannot edit contact when non-primary email address is selected."
                              );
                            } else if (!isGroupSelected) {
                              this.setState({
                                editingContact: row,
                                showContactCardModal: true,
                              });
                            } else {
                              console.log("Disabled: double click");
                            }
                          }
                        }}
                      >
                        <td
                          style={{
                            display: this.props.profileMode ? "none" : "block",
                          }}
                        >
                          {renderRowInput(
                            row.unsubscribed,
                            row.email,
                            key,
                            "contact"
                          )}
                        </td>
                        <td>{row.firstName}</td>
                        <td>{row.lastName}</td>
                        <td>{row.practice}</td>
                        <td>
                          {renderEmailSelect(
                            row,
                            key
                          )}
                        </td>
                        <td>{row.city}</td>
                        <td>{row.state}</td>
                        <td>{row.zip}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Recipients;
