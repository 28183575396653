import React, { Component } from "react";
import axios from "axios";
import config from "react-global-configuration";

class AddContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMesssage: "",
      userFirstName: '',
      userLastName: '',
      userSpecialty: '',
      userAddress: '',
      userCity: '',
      userState: '',
      userZip: '',
      hcp_email_0: '',
      hcp_email_1: '',
      hcp_email_2: '',
      hcp_email_3: '',
    };
  }

  saveContact = () => {
    const required = [this.state.userFirstName, this.state.userLastName, this.state.hcp_email_0];
    if(required.includes('')) {
      this.setState({errorMesssage: "First name, last name, and primary email are required."});
      return;
    } else {
      this.setState({errorMesssage: ''});
    }

    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    let details = {
      firstName: this.state.userFirstName,
      lastName: this.state.userLastName,
      practice: this.state.userSpecialty,
      address: this.state.userAddress,
      city: this.state.userCity,
      state: this.state.userState,
      zip: this.state.userZip,
      hcp_email_address: this.state.hcp_email_0,
      hcp_email_1: this.state.hcp_email_1,
      hcp_email_2: this.state.hcp_email_2,
      hcp_email_3: this.state.hcp_email_3,
    }

    bodyFormData.set("hID", "addContact");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("details", JSON.stringify(details));
    bodyFormData.set("apiKey", this.props.user.api_key);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
       if (response.data.status === 'success') {
          that.setState({errorMesssage: ""});
          that.props.onUpdateContacts(response.data.result);
        } else {
          that.setState({errorMesssage: "There was an error saving this contact."});
        }
      })
      .catch(function (response) {
        that.setState({errorMesssage: "There was an error saving this contact."});
      });
  }

  onHcpAddEdit = (event) => {
    const val = event.target.value;
    const colName = event.target.id;
    this.setState({[colName] : val});
  }

  render() {
    const hcpItems = [];

    for (let i = 0; i < 5; i++) {
      let inputClass = "app__contact__input-text-email";
      let place = "Email address " + i;
      if (i === 0) {
        inputClass += " primary";
        place = "Email address (primary)";
      }

      hcpItems.push(
        <label
          className="app__contact__input-text__label"
          key={"hcp-email-" + i}
        >
          <input
            className={inputClass}
            type="text"
            id={"hcp_email_" + i}
            onChange={this.onHcpAddEdit}
            placeholder={place}
          ></input>
        </label>
      );
    }

    return (
      <div>
      <div className="app__modal contact__modal title">
        <div className="app__modal__container">
          <p>Add Contact</p>
        </div>
      </div>
      <div className="app__modal contact__modal add__modal">
      
          <div className="app__modal__container">
            <div className="app__contact__info-wrapper">
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  onChange={e => this.setState({ userFirstName: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">
                  First Name
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  onChange={e => this.setState({ userLastName: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Last Name
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text specialty"
                  type="text"
                  onChange={e => this.setState({ userSpecialty: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Specialty
                </div>
              </label>

              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  onChange={e => this.setState({ userAddress: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Address
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  onChange={e => this.setState({ userCity: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">City</div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text state"
                  type="text"
                  onChange={e => this.setState({ userState: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">
                  State
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text zip"
                  type="text"
                  onChange={e => this.setState({ userZip: e.target.value })}
                ></input>
                <div className="app__contact__input-text__label-text">Zip</div>
              </label>
            </div>

            <div className="app__contact__add-edit-wrapper">
              <div className="error">
                  {this.state.errorMesssage}
              </div>
              <div className="app__contact__bottom">
              <div className="app__contact__email-forms">
                <div className="app__contact__hcp-emails">
                  <form>{hcpItems}</form>
                </div>
              </div>

              <div className="app__contact__footer">
                <p className="app__contact__footer-info">
                    Top spot indicates primary HCP email address.
                </p>
              </div>
                <button
                  className="app__contact-save"
                  onClick={(event) => {
                    this.saveContact();
                  }}
                >
                  Save
                </button>

                <button
                className="app__contact-save"
                  onClick={(event) => {
                    this.props.handleClose();
                  }}
                >
                  Cancel
               </button>
            </div>
            </div>
          </div>
    
      </div>
      </div>
    );
  }
}

export default AddContactModal;
