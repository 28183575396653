import React, { useState } from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { getContacts } from '../GraphService';
import ContactModal from "../components/ContactModal";

export default function OutlookConnect({ onUpdateContacts, userID, user }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState('');

  const msalConfig = {
    auth: {
      clientId: config.get('outlookAppId'),
      redirectUri: config.get('outlookRedirectUri')
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };

  const getMsalContacts = async () => {
    const msal = new PublicClientApplication(msalConfig);

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal, {
      account: msal.getActiveAccount(),
      scopes: ['contacts.read'],
      interactionType: InteractionType.Popup
    });

    await msal
      .loginPopup({
        scopes: ['contacts.read'],
        prompt: 'select_account'
      })
      .then(value => {
        msal.setActiveAccount(value.account);
      });

    const response = await getContacts(authProvider);

    setContacts(response.value);
    setShowConfirm(true);
  };

  const saveContacts = () => {
    const apiUrl = config.get('apiUrl');
    let bodyFormData = new FormData();
    bodyFormData.set('hID', 'saveOutlookContacts');
    bodyFormData.set('uID', userID);
    bodyFormData.set('contacts', JSON.stringify(contacts));
    bodyFormData.set("apiKey", user.api_key);

    axios({
      method: 'post',
      url: apiUrl + 'index.php',
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (response.data.status === 'success') {
          setError('');
          setShowConfirm(false);
          onUpdateContacts(response.data.result);
        } else {
          setError('There was an error saving this contact.');
          setShowConfirm(false);
        }
      })
      .catch(function (response) {
        setError('There was an error saving this contact.');
        setShowConfirm(false);
      });
  };

  return (
    <div className='app__outlook-connect'>
      <div className='app__recipients__connect'>
        <span className="error">{error}</span>
        <button className='app__recipients__connect-btn app__button app__button--secondary' onClick={() => getMsalContacts()}>
          Import Contacts from Outlook
        </button>
      </div>

      {showConfirm && (
        <div>
          <div className='app__modal contact__modal import__modal overwrite__modal'>
            <div className='app__modal__container'>
              <span className='app__modal__heading'>Save Contacts?</span>
              <p className='app__modal__text'>You are about to add {contacts.length} contacts. This will replace your existing imported contacts (your manually added contacts will remain) - do you wish to continue?</p>
              <button  className='app__button app__contact-save'
                onClick={event => {
                  setShowConfirm(false);
                }}
              >
                Cancel
              </button>

              <button className='app__button app__contact-save'
                onClick={event => {
                    saveContacts();
                }}
              >
                Yes, Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
