import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import CustomSelect from "../components/CustomSelect";

class EditTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateId: null,
      subjects: [],
      subtexts: [],
      greetings: [],
      intros: [],
      closings: [],
      onSubject: "",
      onGreeting: "",
      onIntro: "",
      onClosing: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.templates.length > 0 &&
      prevProps.activeTemplateIndex !== null &&
      prevProps.activeTemplateIndex >= 0
    ) {
      let tID = prevProps.templates[prevProps.activeTemplateIndex].id;

      if (tID && tID !== this.state.templateId) {
        this.setState(
          {
            templateId: tID,
          },
          () => {
            this.getTemplateOptions();
            this.getTemplatePreview(false);
          }
        );
      }

    } else if (prevProps.activeTemplateIndex === -1 && this.state.templateId !== -1) {
      // Force a reset
      this.setState({ templateId: -1 });
    }
  }

  componentDidMount = () => { };

  handlePreview = (event) => {
    this.getTemplatePreview(true);
  };

  optionSelect = (type, val) => {
    this.setState(
      {
        [type]: val,
      },
      () => {
        let details = {
          subject: this.state.onSubject,
          subtext: this.state.subtexts[this.state.onSubject],
          greeting: this.state.onGreeting,
          intro: this.state.onIntro,
          close: this.state.onClosing,
        };

        this.props.handleOptionChange(details);
      }
    );
  };

  getTemplatePreview = (doContentReplace = false) => {
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    let details = {};

    if (this.state.templateId === -1) {
      return;
    }

    bodyFormData.set("hID", "getTemplatePreview");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("bID", this.props.bid);
    bodyFormData.set("tID", this.state.templateId);
    bodyFormData.set("apiKey", this.props.user.api_key);

    if (doContentReplace) {
      details = {
        subject: this.state.onSubject,
        subtext: this.state.subtexts[this.state.onSubject],
        greeting: this.state.onGreeting,
        intro: this.state.onIntro,
        close: this.state.onClosing,
      };
      bodyFormData.set("details", JSON.stringify(details));
    }

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        let el = document.getElementById("previewHtmlEdit");
        el.contentDocument.body.innerHTML = response.data.result;

        let links = el.contentDocument.body.getElementsByTagName('a');
        for (var i = 0, len = links.length; i < len; i++) {
          links[i].onclick = function () {
            return false;
          }
        }

        el.contentDocument.body.scrollTo(0, 0);
      })
      .catch(function (response) {
        console.log("Error getting template options");
      });
  };

  getTemplateOptions = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getTemplateOptions");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("tID", this.state.templateId);
    bodyFormData.set("apiKey", this.props.user.api_key);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        that.setState({
          subjects: response.data.result.subjects,
          subtexts: response.data.result.subtexts,
          greetings: response.data.result.greetings,
          intros: response.data.result.intros,
          closings: response.data.result.closings,
          onSubject: response.data.result.subjects[0],
          onGreeting: response.data.result.greetings[0],
          onIntro: response.data.result.intros[0],
          onClosing: response.data.result.closings[0],
        });

        // Pass default select values to parent
        let details = {
          subject: response.data.result.subjects[0],
          subtext:
            response.data.result.subtexts[response.data.result.subjects[0]],
          greeting: response.data.result.greetings[0],
          intro: response.data.result.intros[0],
          close: response.data.result.closings[0],
        };

        that.props.handleOptionChange(details);
      })
      .catch(function (response) {
        console.log("Error getting template options");
      });
  };

  render() {
    return (
      <section
        className="app__select-topic"
        key={`template-` + this.props.activeTemplateIndex}
      >
        <p>Please review the details below to complete your email content.</p>
        <label>Subject Line</label>
        <CustomSelect
          type="onSubject"
          options={this.state.subjects}
          onSelect={this.optionSelect}
        />
        <div className="app__dropdowns unselectable">
          <label>Greeting</label>
          <CustomSelect
            type="onGreeting"
            options={this.state.greetings}
            onSelect={this.optionSelect}
          />
          {this.props.bid !== 3 && this.state.intros[0] && (
            <div>
              <label>Introduction</label>
              <CustomSelect
                type="onIntro"
                options={this.state.intros}
                onSelect={this.optionSelect}
              />
            </div>)}

          {this.props.bid !== 3 && this.state.closings[0] && (
            <div>
              <label>Closing</label>
              <CustomSelect
                type="onClosing"
                options={this.state.closings}
                onSelect={this.optionSelect}
              />
            </div>
          )}
          <button
            className="app__select-topic__button"
            onClick={(event) => this.handlePreview(event)}
          >
            Preview My Email
          </button>
        </div>
        <div className="app__email-preview app__center">
          <iframe
            title="Preview"
            className="templatePreview"
            id="previewHtmlEdit"
          ></iframe>
        </div>
      </section>
    );
  }
}

export default EditTemplate;
