import React, { Component } from "react";
import GridCell from "../components/GridCell";
import config from "react-global-configuration";
import axios from "axios";

class EmailGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridItems: [],
      subject: "",
      init_greet: "",
      contentA: "",
      contentB: "",
      contentC: "",
      close_email: "",
      activeBrand: null,
      totalRows: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bid !== null && prevProps.bid !== this.state.activeBrand) {
      this.setState(
        {
          activeBrand: prevProps.bid,
        },
        () => {
          this.getEmailGrid(prevProps.bid);
        }
      );
    }
  }

  callReset(brandId) {
    this.getEmailGrid(brandId);
    let elem = document.getElementById('emailGridContainerParent');
    let className = elem.className;
    if (className === "app__flex-table hidden" && document.querySelectorAll(".app__flex-table__cell--selected").length > 0) { 
      const selectedEls = document.querySelectorAll(
        ".app__flex-table__cell--selected"
      );
      for (const el of selectedEls) {
        el.classList.remove("app__flex-table__cell--selected");
      }
    }
  }

  callBrandReload(brandId) {
    this.getEmailGrid(brandId);
  }

  getEmailGrid = (brandId) => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getEmailGrid");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("apiKey", this.props.user.api_key);
    bodyFormData.set("bID", brandId);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        // unselect all
        const selectedEls = document.querySelectorAll(
          ".app__flex-table__cell--selected"
        );
        for (const el of selectedEls) {
          el.classList.remove("app__flex-table__cell--selected");
        }

        let maxLen = response.data.result["subject"].length;
        if (response.data.result["init_greet"].length > maxLen) {
          maxLen = response.data.result["init_greet"].length;
        }
        if (response.data.result["contentA"].length > maxLen) {
          maxLen = response.data.result["contentA"].length;
        }
        if (response.data.result["contentB"].length > maxLen) {
          maxLen = response.data.result["contentB"].length;
        }
        if (response.data.result["contentC"].length > maxLen) {
          maxLen = response.data.result["contentC"].length;
        }
        if (response.data.result["close_email"].length > maxLen) {
          maxLen = response.data.result["close_email"].length;
        }

        that.setState({
          gridItems: response.data.result,
          subject: "",
          init_greet: "",
          contentA: "",
          contentB: "",
          contentC: "",
          close_email: "",
          totalRows: maxLen,
          activeBrand: brandId
          
        });
      })
      .catch(function (response) {
        console.log("Error getting email grid");
      });
  };

  handleSelect = (event, col, row) => {
    let el = event.target;

    if (el.tagName === "IMG") {
      return;
    }

    if(el.tagName === "SPAN") {
      el = event.target.parentElement;
    }
    
    if (el.id === "") {
      return;
    }

    // Do nothing if this item is already selected
    if (this.state[col] === el.id) {
      return;
    }
    
    if (this.state[col] !== "") {
      document
        .getElementById(this.state[col])
        .classList.remove("app__flex-table__cell--selected");
    }
    el.classList.add("app__flex-table__cell--selected");

    this.setState({ [col]: el.id }, () => {
      this.checkGridSelected();
    });
  };

  checkGridSelected = () => {
    if (
      this.state.subject !== "" &&
      this.state.init_greet !== "" &&
      this.state.contentA !== "" &&
      this.state.contentB !== "" &&
      this.state.contentC !== "" &&
      this.state.close_email !== ""
    ) {
      let details = {
        subject: this.state.subject,
        init_greet: this.state.init_greet,
        contentA: this.state.contentA,
        contentB: this.state.contentB,
        contentC: this.state.contentC,
        close_email: this.state.close_email,
      };
      this.props.handleGridSelected(true, details);
    } else {
      this.props.handleGridSelected(false);
    }
  };

  createGrid = () => {
    let rows = [];
    let titles = [
      "subject",
      "init_greet",
      "contentA",
      "contentB",
      "contentC",
      "close_email",
    ];
    
    for (let i = 0; i < this.state.totalRows; i++) {
      let children = [];

      for (let j = 0; j < titles.length; j++) {
        let title = titles[j];
        let items = this.state.gridItems;

        if (items[title][i]) {
          children.push(
            <GridCell
              key={"cell-" + i + "-" + j}
              id={items[title][i]}
              onCellClick={(event) => this.handleSelect(event, title, i)}
              cellText={this.state.gridItems[title][i]}
            />
          );
        } else {
          children.push(<GridCell key={"cell-" + i + "-" + j} cellText="" />);
        }
      }

      rows.push(
        <div key={"gridrow-" + i} className="app__flex-table__row">
          {children}
        </div>
      );
    }

    return rows;
  };

  render() {
    return (
      <div id="emailGridContainer">
      <p>Create your email text by selecting a phrase from each of the columns below.</p>
        {this.state.gridItems["subject"] &&
          this.state.gridItems["init_greet"] &&
          this.state.gridItems["contentA"] &&
          this.state.gridItems["contentB"] &&
          this.state.gridItems["contentC"] &&
          this.state.gridItems["close_email"] && (
            <div className="email-grid">
              <div className="app__flex-table__row--header">
                <div className="app__flex-table__cell--header">
                  Subject Line
                </div>
                <div className="app__flex-table__cell--header">Greeting</div>
                <div className="app__flex-table__cell--header">
                  Introduction
                </div>
                <div className="app__flex-table__cell--header">Body Text</div>
                <div className="app__flex-table__cell--header">
                  Call to Action
                </div>
                <div className="app__flex-table__cell--header">Closing</div>
              </div>

              {this.createGrid()}
            </div>
          )}
      </div>
    );
  }
}

export default EmailGrid;
