import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="app__footer">
        <div className="app__footer__container">
          <div className="left-col">
            <img src="/img/vericel_logo.png" className="footer__logo" />
            <div className="clearfix"></div>
            <p>MACI®, Vericel®, and the Vericel logo are registered trademarks of Vericel Corporation.</p>
            <p>©2022 Vericel Corporation. All rights reserved. <span className="footer__pipe">|</span> PP.US.MAC.1671 05/22</p>
          </div>
          <div className="right-col">
            <p>For internal MACI rep usage, not external facing.</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
