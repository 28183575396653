import React, { Component } from "react";

class ISI extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isiOpen : false
      }
    };

    collapseISI = () => {
      this.setState({
        isiOpen : !this.state.isiOpen
      });
    }


  render() {
    return (

<div className="isi--inline">
   <div className="col-md-6">
      <div className="nwei">
         <h4>Indication</h4>
         <p>MACI<sup>®</sup> (autologous cultured chondrocytes on porcine collagen membrane) is an autologous cellularized scaffold product that is indicated for the repair of single or multiple symptomatic, full-thickness cartilage defects of the adult knee, with or without bone involvement.</p>
         <p>MACI is intended for autologous use and must only be administered to the patient for whom it was manufactured. The implantation of MACI is to be performed via an arthrotomy to the knee joint under sterile conditions.</p>
         <p>The amount of MACI administered is dependent upon the size (surface in cm²) of the cartilage defect. The implantation membrane is trimmed by the treating surgeon to the size and shape of the defect, to ensure the damaged area is completely covered, and implanted cell-side down.</p>
         <p><strong>Limitations of Use</strong></p>
         <p>Effectiveness of MACI in joints other than the knee has not been established.</p>
         <p>Safety and effectiveness of MACI in patients over the age of 55 years have not been established.</p>
      </div>
   </div>
   <div className="col-md-6">
      <div id="isi"></div>
      <div className="nwei">
         <h4>Important safety information</h4>
         <p>MACI is contraindicated in patients with a known history of hypersensitivity to gentamicin, other aminoglycosides, or products of porcine or bovine origin. MACI is also contraindicated for patients with severe osteoarthritis of the knee, inflammatory arthritis, inflammatory joint disease, or uncorrected congenital blood coagulation disorders. MACI is also not indicated for use in patients who have undergone prior knee surgery in the past 6 months, excluding surgery to procure a biopsy or a concomitant procedure to prepare the knee for a MACI implant.</p>
         <p>MACI is contraindicated in patients who are unable to follow a physician-prescribed post-surgical rehabilitation program.</p>
         <p>The safety of MACI in patients with malignancy in the area of cartilage biopsy or implant is unknown. Expansion of present malignant or dysplastic cells during the culturing process or implantation is possible.</p>
         <p>Patients undergoing procedures associated with MACI are not routinely tested for transmissible infectious diseases. A cartilage biopsy and MACI implant may carry the risk of transmitting infectious diseases to healthcare providers handling the tissue. Universal precautions should be employed when handling the biopsy samples and the MACI product.</p>
      </div>
      {!this.state.isiOpen &&
        <div className="isi__expand" onClick={this.collapseISI}>Click here to expand</div>
      }
      <div id="collapse-more" className={'isi__collapse ' + (this.state.isiOpen ? 'open' : '')}>
         <div>
            <p>Final sterility test results are not available at the time of shipping. In the case of positive sterility results, health care provider(s) will be contacted.</p>
            <p>To create a favorable environment for healing, concomitant pathologies that include meniscal pathology, cruciate ligament instability and joint misalignment, must be addressed prior to or concurrent with the implantation of MACI.</p>
            <p>Local treatment guidelines regarding the use of thromboprophylaxis and antibiotic prophylaxis around orthopaedic surgery should be followed. Use in patients with local inflammations or active infections in the bone, joint, and surrounding soft tissue should be temporarily deferred until documented recovery.</p>
            <p>The MACI implant is not recommended during pregnancy. For implantations post-pregnancy, the safety of breast feeding to infant has not been determined.</p>
            <p>Use of MACI in pediatric patients (younger than 18 years of age) or patients over 65 years of age has not been established.</p>
            <p>The most frequently occurring adverse reactions reported for MACI (≥5%) were arthralgia, tendonitis, back pain, joint swelling, and joint effusion.</p>
            <p>Serious adverse reactions reported for MACI were arthralgia, cartilage injury, meniscus injury, treatment failure, and osteoarthritis.</p>
            <p>Please see <a href="https://www.maci.com/pdf/maci-full-prescribing-information.pdf" target="_blank">Full Prescribing Information</a> for more information</p>
         </div>
      </div>
      {this.state.isiOpen &&
        <div className="isi__expand" onClick={this.collapseISI}>Click here to collapse</div>
      }
   </div>
   <div className="clearfix"></div>
</div>

    )
  }
}

  export default ISI;