import { Client } from '@microsoft/microsoft-graph-client';

let graphClient = undefined;

function ensureClient(authProvider ) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });
  }

  return graphClient;
}

export async function getContacts(authProvider)  {
  ensureClient(authProvider);

  return await graphClient
  .api('/me/contacts/?$top=1000')
  .get();
}
