import React, { Component } from "react";
import PrivateApp from "./components/PrivateApp";
import SignIn from "./components/SignIn";
import Footer from "./components/Footer";
import config from "react-global-configuration";
import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);
    
    config.set({
      apiUrl: process.env.REACT_APP_SERVER_URL,
      outlookAppId: process.env.REACT_APP_OUTLOOK_APP_ID,
      outlookRedirectUri: process.env.REACT_APP_OUTLOOK_REDIRECT_URL,
    }, { freeze: false });

    this.state = {
      error: null,
      authed: false,
      user: {}
    };

  }

  handleLogin = (userData, isRefresh = false) => {
    let loggedInUser = {
      id: userData.id,
      name: userData.name,
      title: userData.title,
      phone: userData.phone,
      photo: userData.photo,
      email: userData.email,
      region: userData.region,
      api_key: userData.api_key
    };

    this.setState({
      authed: true,
      user: loggedInUser,
    });
  };

  handleLogout = () => {
    this.setState({ authed: false, user: null });
  };

  handleUserUpdated = (newDetails) => {
    let newUser = {
      id: this.state.user.id,
      name: newDetails.fullname,
      title: newDetails.title,
      phone: newDetails.phone,
      photo: this.state.user.photo,
      email: this.state.user.email,
      region: this.state.user.region,
      api_key: this.state.user.api_key
    };

    this.setState({ user: newUser });
  };

  render() {

    if (this.state.authed) {
      return (
        <PrivateApp
          onLogout={this.handleLogout}
          user={this.state.user}
          handleUserUpdated={this.handleUserUpdated}
        />
        
      );
    } else {
      return (
        <div className="app admin">
          <div className="landscapeOverlay">
            <p>Small mobile devices are not supported.</p>
          </div>
          <header className="app__header"></header>
          <div className="app__container">
            <SignIn onLogin={this.handleLogin} />
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default App;
